import React from 'react';

import classnames from 'classnames/bind';

import { PreviewData } from '@INTERFACES/api/preview';

import styles from './PreviewSidebar.module.scss';

const cx: CX = classnames.bind(styles);

type SectionItem = {
    id: number;
    name: string;
    sectionName: string;
    children: SectionItem[] | [];
};

type SectionResult = {
    Section: SectionItem[];
};

function PreviewSidebar(props: Props) {
    const { previewData } = props;

    function handleChapterClick(id: number) {
        const anchor = `chapter-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    function handleIntroductionTextClick(id: number) {
        const anchor = `introduction-text-chapter-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    function handleSectionClick(id: number) {
        const anchor = `section-${id}`;

        const anchorEl = document.getElementById(anchor);

        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }

    const sectionView = (result: SectionResult) => {
        const renderList = (list: any[]) => (
            <ul>
                {list.map((sec) => {
                    const ch = sec?.children;
                    return (
                        <>
                            {sec.sectionName}
                            <li key={sec.name}>
                                <button
                                    type="button"
                                    className={cx('link', 'clear-button')}
                                    onClick={() => {
                                        handleSectionClick(sec.id);
                                    }}
                                >
                                    {sec.name}
                                </button>
                                {ch.length > 0 && renderList(ch)}
                            </li>
                        </>
                    );
                })}
            </ul>
        );

        return (
            <ul>
                {result.Section.map((sec, index) => {
                    const ch = sec.children;
                    return (
                        <>
                            {sec.sectionName}
                            <li key={sec.name}>
                                <button
                                    type="button"
                                    className={cx('link', 'clear-button')}
                                    onClick={() => {
                                        handleSectionClick(sec.id);
                                    }}
                                >
                                    {sec.name}
                                </button>
                                {renderList(ch)}
                            </li>
                        </>
                    );
                })}
            </ul>
        );
    };

    return (
        <div className={cx('preview-sidebar')}>
            <div className="container-left">
                <div className={cx('chapters-title')}>
                    Chapters
                </div>
                <div className={cx('chapters-list')}>
                    {
                        previewData.map((chapter) => {
                            const chapterKey = chapter.id;
                            const result = chapter.sections.reduce<SectionResult>(
                                (acc, curSec) => {
                                    const { children: subSections = [] } = curSec;

                                    // Map sub-sub-sections
                                    const subsubSectionItems = subSections.flatMap((subSec) => (subSec.children || []).map((subsubSec) => ({
                                        id: subsubSec.id,
                                        name: subsubSec.name,
                                        sectionName: 'Sub Sub Section',
                                        children: [],
                                    })));

                                    // Map sub-sections
                                    const subSectionItems = subSections.map((subSec) => ({
                                        id: subSec.id,
                                        name: subSec.name,
                                        sectionName: 'Sub Section',
                                        children: subsubSectionItems,
                                    }));

                                    // Return updated accumulator with the correct keys
                                    return {
                                        Section: [...acc.Section, {
                                            id: curSec.id,
                                            name: curSec.name,
                                            sectionName: 'Section',
                                            children: subSectionItems,
                                        }],
                                    };
                                },
                                { Section: [] },
                            );

                            return (
                                <div
                                    key={chapterKey}
                                    className={cx('chapter-list-item')}
                                >
                                    <div className={cx('chapter')}>
                                        <button
                                            type="button"
                                            className={cx('link', 'clear-button')}
                                            onClick={() => {
                                                handleChapterClick(chapter.id);
                                            }}
                                        >
                                            {chapter.name}
                                        </button>
                                    </div>
                                    {
                                        chapter.hasBody
                                        && (
                                            <div className={cx('introduction-text')}>
                                                <button
                                                    type="button"
                                                    className={cx('link', 'clear-button')}
                                                    onClick={() => {
                                                        handleIntroductionTextClick(chapter.id);
                                                    }}
                                                >
                                                    Introduction text
                                                </button>
                                            </div>
                                        )
                                    }
                                    {sectionView(result)}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

type Props = {
    previewData: PreviewData,
};

export default PreviewSidebar;
